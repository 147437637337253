export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyB-pnKrD0TtjRdHFNWERchPLOrZTst7Id0',
    authDomain: 'ovvisuals-ecommerce.firebaseapp.com',
    databaseURL: 'https://ovvisuals-ecommerce.firebaseio.com',
    projectId: 'ovvisuals-ecommerce',
    storageBucket: 'ovvisuals-ecommerce.appspot.com',
    messagingSenderId: '391233476360',
    appId: '1:391233476360:web:74071ce2d003e88f82febd',
    measurementId: 'G-CDEH2918Y5',
  },
};
